import * as React from 'react';

global.Buffer = global.Buffer || require('buffer').Buffer;

if (typeof btoa === 'undefined') {
  global.btoa = function (str) {
    return new Buffer(str, 'binary').toString('base64');
  };
}

if (typeof atob === 'undefined') {
  global.atob = function (b64Encoded) {
    return new Buffer(b64Encoded, 'base64').toString('binary');
  };
}

const THEME_COLOR = '#457de4'; // accent-3
const THEME_COLOR2 = '#457de4'; // accent-3 as well
const THEME_COLOR_BORDER = '#17467F';
const THEME_COLOR_BORDER_ALT = 'rgb(74, 74, 74)';

const SVG_IMAGE_PREFIX = 'data:image/svg+xml;base64,';

function icon(src, alt) {
  if (src.startsWith(SVG_IMAGE_PREFIX)) {
    const base64Data = src.substring(SVG_IMAGE_PREFIX.length);
    const data = atob(base64Data);
    let nextData = data.replace(/#f0bc5e|#FFD071|#F9BA48|#FFA64D|#FFCB57/ig, THEME_COLOR);
    nextData = nextData.replace(/#E56565/ig, THEME_COLOR2);
    const themeColorBorder = alt ? THEME_COLOR_BORDER_ALT : THEME_COLOR_BORDER;
    nextData = nextData.replace(/#3F3A34|#333333|rgb\(74, 74, 74\)/ig, themeColorBorder);
    return `${SVG_IMAGE_PREFIX}${btoa(nextData)}`;
  }
  return src;
}

export default ({ src, alt }) => {
  // TODO: hook
  return <img src={icon(src, !!alt)} />;
};
